<template>
  <div id="partner-wrapper">
    <div class="banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>我们的伙伴</div>
        <div>OUR PARTNERS</div>
      </div>
      <SubNav :navList="navList" />
    </div>
    <div id="information-list-wrapper" v-html="partners"></div>
  </div>
</template>
<script>
// import Pagination from "@/components/Pagination.vue";
import SubNav from "@/components/SubNav";
import { getConfig } from "@/api/category";

export default {
  name: "Partners",
  // props: ["informationList", "newList", "activeIndex"],
  components: {
    // Pagination
    SubNav
  },
  data() {
    return {
      navList: [
        {
          name: "我们的伙伴",
          englishName: "",
          path: "partners",
          component: "Partners"
        }
        // {
        //   name: "项目故事",
        //   englishName: "PROJECT STORY",
        //   path: "/news/story",
        //   component: "Story"
        // }
      ],
      partners: null
    };
  },
  computed: {
    newList() {
      let newList = [];
      for (let i = 0; i < this.informationList.length; i += 5) {
        let segment = this.informationList.slice(i, i + 5);
        newList.push(segment);
      }
      return newList;
    },
    activeIndex() {
      return this.currentIndex;
    }
  },
  methods: {
    async getConfig() {
      const res = await getConfig("PartnersPage");
      if (res !== null) {
        try {
          this.partners = res.value;
        } catch (e) {
          this.partners = null;
        }
      }
    }
  },
  mounted() {
    this.getConfig();
  }
};
</script>

<style lang="scss" scoped>
#partner-wrapper {
  color: #1d1d1f;
}
#information-list-wrapper {
  width: 1240px;
  margin: 0 auto 100px;
}
.banner {
  background-image: url(../../assets/images/two-banner.jpeg);
  background-position: 50% 35%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 80vw;
  min-width: 1240px;
  max-width: 100%;
  height: 23vw;
  min-height: 340px;
  max-height: 430px;
  margin: 0 auto;
  background-size: cover;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(37, 163, 246, 0.267);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      font-size: 40px;
      margin-bottom: 30px;
    }
    div:last-child {
      font-size: 20px;
    }
  }
}
#information-list {
  ul {
    margin: 0;
    padding: 0;
    li {
      transition: all ease 0.1s;
      cursor: pointer;
      width: 100%;
      background-color: #f5f5f5;
      padding: 40px 60px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      position: relative;
      a {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .left {
        // width: 386px;
        flex: 1 0 664px;
        padding-right: 70px;
        .information-date {
          font-size: 14px;
          color: #999;
        }
        .information-title {
          font-size: 24px;
          margin: 30px 0;
        }
        .information-abstract {
          line-height: 30px;
        }
      }
      .right {
        flex: 0 0 386px;
        height: 260px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    li:hover {
      background-color: #004898;
      color: #fff;
      .information-date {
        color: #fff;
      }
    }
  }
}
.information-list-name {
  font-size: 24px;
  line-height: 48px;
}
</style>
